import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils/grid';

export const root = css`
  @media ${breakpoints.large} {
    max-width: calc(var(--unit) * 160);
  }
`;

export const heading = css`
  justify-content: space-between;
  margin-bottom: calc(var(--unit) * 15);

  h3 {
    margin-bottom: ${spacing(16)};

    @media ${breakpoints.medium} {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.medium} {
    display: flex;
  }
`;

export const newBtn = css`
  path {
    fill: hsl(var(--color-grayscale-1));
  }
`;

export const name = css`
  margin: 0;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: var(--font-weight-bold);
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: calc(var(--unit) * 2);
`;

export const role = css`
  margin: 0;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-4));
`;

export const btnGroup = css`
  display: flex;
  align-items: center;
  margin-top: ${spacing(16)};

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

export const editBtn = css`
  margin-left: calc(var(--unit) * 5);
  path {
    fill: hsl(var(--color-grayscale-4));
  }

  &:hover {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;

export const deactivateBtn = css`
  background: hsl(var(--color-warning-6));
  margin-left: calc(var(--unit) * 5);

  &:hover,
  &:active {
    background-color: hsl(var(--color-warning-5));
  }
`;

export const modal = css`
  display: grid;
  ${grid.template.cols1};
  row-gap: calc(var(--unit) * 4);

  ol {
    margin: 0;
    padding-inline-start: 20px;
    color: hsl(var(--color-grayscale-6));

    li {
      margin-bottom: calc(var(--unit) * 2);
      padding-left: calc(var(--unit) * 2);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const modalButtons = css`
  margin-top: ${spacing(32)};
`;

export const modalTitle = css`
  margin: 0 0 calc(var(--unit) * 8);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);
  font-family: var(--font-family-secondary);
  font-weight: normal;
`;

export const modalInfo = css`
  background: hsl(var(--color-error-9));
  padding: calc(var(--unit) * 2) calc(var(--unit) * 4);
  border-radius: 3px;
  color: hsl(var(--color-text-1));
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
`;

export const modalContentHighlight = css`
  color: hsl(var(--color-text-1));
`;

export const modalContent = css`
  color: hsl(var(--color-grayscale-6));
`;

export const modalError = css`
  color: hsl(var(--color-warning-6));
  margin: calc(var(--unit) * 4) 0 calc(var(--unit) * 10);
`;

export const modalDeactivateBtn = css`
  ${deactivateBtn};
  margin-left: 0;
  margin-right: calc(var(--unit) * 5);
  margin-top: calc(var(--unit) * 4);
`;

export const closeButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const actionButtonContainer = css`
  display: flex;
  align-items: flex-end;

  & > span {
    font-size: var(--font-size-small);
  }
`;

export const numberField = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-of-type {
    width: 100%;
  }

  & > span:last-of-type {
    flex: 0;
    margin-left: calc(var(--unit) * 8);
  }
`;
